import React, { useEffect, useState } from 'react'



const SuccessHandler = () => {
  const redirect = window.sessionStorage.getItem('callbackurl')

  window.sessionStorage.removeItem('token')

  if (redirect) {
    setTimeout(() => {
      if(redirect === '#close'){
        return false
      }
      window.sessionStorage.removeItem('callbackurl')
      window.location.href = `${redirect}`
    }, 3000)
  }


  return (
    <>
      <h1>
        <span>Tudo </span>pronto!
      </h1>
      <hr />
      <p className="text-center">
        Seu cadastro facial foi realizado com sucesso
        <br />
        Obrigado!
      </p>
      <p>

      </p>
    </>
  )
}

export default SuccessHandler
